define("discourse/plugins/discourse-webauth/discourse/routes/webauth-connect", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Discourse.Route.extend({
    renderTemplate() {
      // Renders the template `../templates/webauth.hbs`
      this.render('webauth');
    }
  });
});